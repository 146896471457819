@import "../../Base.scss";

.divider {
  width: 50%;
  height: 2px;
  margin: 50px 0 50px 25%;
  background-color: #666666;
  border-radius: 300px;
}

.main {
  background-color: $base-color;
  color: $font-color;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;

  #main,
  #contact,
  #my-career,
  #my-technologies {
    scroll-margin-top: 72px;

    @media only screen and (max-width: 768px) {
      gap: 0 !important;
    }
  }

  .content {
    max-width: 1200px;
  }

  #home {
    margin-top: 28px;
    margin-bottom: 28px;
  }

  #navbar {
    margin: 0 0 20px 0;
    bottom: 0;

    .navbar-container {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 64px;
      width: 100%;
    }

    .menu-button {
      background: none;
      color: inherit;
      border: none;
      cursor: pointer;
      outline: inherit;
    }
  }

  .menu-button {
    background: none;
    color: inherit;
    border: none;
    cursor: pointer;
    outline: inherit;
  }

  #mobile-floating-menu-button {
    @media only screen and (max-width: 768px) {
      background-color: white;
      color: black;
      width: 52px;
      height: 52px;
      position: fixed;
      bottom: 18px;
      right: 18px;
      border-radius: 300px;
      z-index: 10000;
      
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      .menu-mobile-icon {
        transition: all 500ms;

      }

      .show {
        display: inline;
      }

      .hide {
        display: none;
      }
    }
  }

  #mobile-menu {
    display: none;

    &.open {
      position: fixed;
      z-index: 9999;
      backdrop-filter: blur(10px) brightness(0.6);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 24px;

      overscroll-behavior: contain;

      a {
        font-size: 24px;
      }
    }
  }

  #home {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-height: 328px;
      max-width: 328px;
      object-fit: cover;
    }

    .about-me-text {
      display: inline;

      h1,
      h2,
      h3,
      h4,
      h5 {
        margin: 0;
        padding: 0;
      }
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;


      img {
        // display: none;
        max-height: 280px;
        max-width: 280px;
      }

      .about-me-text {
        display: inline;

        h1,
        h2,
        h3 {
          text-align: center;
        }
      }
    }

    // /* Medium devices (landscape tablets, 768px and up) */
    // @media only screen and (min-width: 768px) {...}
    // /* Extra large devices (large laptops and desktops, 1200px and up) */
    // @media only screen and (min-width: 1200px) {...}
  }

  footer {
    margin-top: auto;
    width: 100%;
    text-align: center;
  }

  a.link,
  a.link:visited {
    text-decoration: none;
    color: $font-color;
    font-size: 18px;

    &::after {
      content: '';
      width: 0px;
      height: 1px;
      display: block;
      background: $font-color;
      transition: 250ms;
    }

    &:hover::after,
    &.active::after {
      width: 100%;
    }
  }
}


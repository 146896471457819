@import "./Base.scss";

h1 {
  font-size: 42px;
}

p {
  font-size: 18px;
}

img#language-toggle {
  transition: all 200ms;

  &:hover {
    cursor: pointer;
    filter: brightness(0.8);
    transition: all 200ms;
  }

  @media only screen and (max-width: 768px) {
    height: 48px;
    width: 48px;
  }
}

.mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .no-mobile {
    display: none;
  }

  .mobile {
    display: block;
  }

  // Force navbar to be hidden, seems like we can't add a class to the MUI component?
  #navbar {
    display: none !important;
  }
}
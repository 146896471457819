@import "../../Base.scss";

.print-button {
  position: fixed;
  bottom: 75px;
  right: 75px;
  box-shadow: #555 4px 3px 11px;
}

.divider {
  width: 50%;
  height: 2px;
  margin: 25px 0 25px 25%;
  background-color: #666666;
  border-radius: 300px;
}

.curriculum-vitae {
  background-color: $base-color;
  color: $font-color;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  // padding-top: 16px;

  #main,
  #contact,
  #my-career {
    scroll-margin-top: 72px;
  }

  .content {
    max-width: 1200px;
  }

  #home {
    margin-top: 28px;
    margin-bottom: 28px;
  }

  #navbar {
    margin: 0 0 20px 0;

    a.link,
    a.link:visited {
      text-decoration: none;
      color: $font-color;
      font-size: 18px;

      &::after {
        content: '';
        width: 0px;
        height: 1px;
        display: block;
        background: $font-color;
        transition: 250ms;
      }

      &:hover::after,
      &.active::after {
        width: 100%;
      }
    }
  }

  #home {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 64px;

    img {
      max-height: 256px;
      max-width: 256px;
      object-fit: cover;
    }

    .about-me-text, .my-technologies-container {
      display: inline;

      h1,
      h2,
      h3,
      h4,
      h5 {
        margin: 0;
        padding: 0;
      }
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;


      img {
        // display: none;
        max-height: 280px;
        max-width: 280px;
      }

      .about-me-text {
        display: inline;

        h1,
        h3 {
          text-align: center;
        }
      }
    }

    // /* Medium devices (landscape tablets, 768px and up) */
    // @media only screen and (min-width: 768px) {...}
    // /* Extra large devices (large laptops and desktops, 1200px and up) */
    // @media only screen and (min-width: 1200px) {...}
  }

  footer {
    margin-top: auto;
    width: 100%;
    text-align: center;
  }

  .social-media-icons {
    margin: 16px;
  }

  a.social-media-icon,
  a.social-media-icon:visited,
  a.my-website-link {
    text-decoration: none;
    color: $font-color;

    margin: 10px 0;
    transition: all 300ms;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    &:hover {
      cursor: pointer;
      color: lightgray;
      transition: all 300ms;

      &.instagram-icon {
        color: #C13584;
      }

      &.linkedin-icon {
        color: #0a66c2;
      }

      &.gmail-icon {
        color: #C71610;
      }
      
      &.my-website-link {
        color: lightblue;
      }

    }
  }
}
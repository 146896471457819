$base-color: #000;
$font-color: white;

html {
  scroll-behavior: smooth;
}

@media print {

  .no-print,
  .no-print * {
    display: none !important;
  }
}